

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/guide-transfert-vente-achat-entreprise-quebec-avocat.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost29 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Guide de transfert, vente et d’achat d’entreprise au Québec - Avocat pour entreprise - Soumissions Avocat"
        description="Le moment est finalement venu de vendre votre entreprise? Vous souhaitez encaisser un maximum de profit à votre départ et obtenir le juste prix pour votre entreprise? Soumissions Avocat vous réfère aux meilleurs experts en vente, transfert et achat d’entreprise au Québec!"
        image={LeadImage}>
        <h1>Guide de transfert, vente et d’achat d’entreprise au Québec – Avocat pour entreprise</h1>

					
					
						<p>Vendre une entreprise après des années de dur labeur n’a rien d’une mince affaire. La préservation du patrimoine, des emplois et de l’intégrité économique de l’entreprise sont les enjeux primordiaux de la démarche de transfert et nécessitent un accompagnement professionnel de pointe. Que vous vous trouviez du côté acheteur ou vendeur de la transaction, les décisions à prendre sont nombreuses et dicteront la profitabilité de la vente une fois celle-ci conclue.</p>
<p><StaticImage alt="Le guide de transfert et vente entreprise Québec" src="../images/guide-transfert-vente-achat-entreprise-quebec-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>C’est d’ailleurs la raison pour laquelle la mise en place d’un plan de transaction concret et d’une équipe diversifiée de professionnels est la première étape vers la réussite de ce processus. </strong></p>
<p>En connaissez-vous réellement assez sur votre propre entreprise pour assurer son transfert sans aide externe? Permettez-nous d’en douter. Voici donc, juste pour vous, propriétaires d’entreprises, <strong>le Guide de transfert, vente et d’achat d’entreprise au Québec!</strong></p>
<ol>
<li><strong><a href="#professionnels-vente-entreprise">Qui sont les professionnels impliqués dans le processus de vente d’entreprise?</a></strong>
<ol>
<li><a href="#avocats">Avocats</a></li>
<li><a href="#comptables">Comptable professionnel agréé</a></li>
<li><a href="#notaires">Notaires</a></li>
<li><a href="#fiscalistes">Fiscaliste</a></li>
<li><a href="#courtiers">Courtier en vente d’entreprise</a></li>
</ol>
</li>
<li><strong><a href="#business-staging">Choisir son équipe de transfert d’entreprise et mise en place du Business Staging</a></strong>
<ol>
<li><a href="#acquisition-strategique">L’acquisition stratégique</a></li>
<li><a href="#exoneration-gain-capital">Exonération du gain en capital</a></li>
</ol>
</li>
<li><strong><a href="#methodes-evaluation-entreprise">Méthodes d’évaluation d’entreprise : combien vaut réellement votre compagnie?</a></strong>
<ol>
<li><a href="#juste-valeur-marchande">Juste valeur marchande (JVM)</a></li>
<li><a href="#actif-net-redresse">Actif net redressé</a></li>
<li><a href="#valeur-liquidation-forcee">Valeur de liquidation forcée</a></li>
<li><a href="#valeur-liquidation-ordonnee">Valeur de liquidation ordonnée</a></li>
<li><a href="#valeur-multiples-benefices">Valeur du multiple des bénéfices (BAIIA)</a></li>
<li><a href="#actualisation-flux-monetaires">Actualisation des flux monétaires prévisibles</a></li>
<li><a href="#valeurs-fondes-marche">Valeurs fondées sur le marché</a></li>
</ol>
</li>
<li><strong><strong><a href="#importance-bon-acheteur">L’importance du bon acheteur pour assurer la pérennité de votre entreprise</a></strong></strong></li>
<li><strong><a href="#vente-actifs-actions-ou-fusion">Vente d’actifs, d’actions ou fusion? Des incidences légales et fiscales!</a></strong>
<ol>
<li><a href="#vente-actifs">Vente d’actifs</a></li>
<li><a href="#vente-actions">Vente d’actions</a></li>
<li><a href="#fusions-acquisitions">Fusions et acquisitions</a></li>
<li><a href="#vente-hybride">Vente hybride</a></li>
</ol>
</li>
<li><strong><a href="#vente-fonds-commerce">En quoi consiste la vente du fonds de commerce?</a></strong></li>
<li><strong><a href="#transfert-entreprise-familiale">Transfert d’une entreprise familiale : un mécanisme particulier</a></strong>
<ol>
<li><a href="#gel-successoral">Gel successoral</a></li>
<li><a href="#fiducie-discretionnaire">Fiducie discrétionnaire</a></li>
<li><a href="#testament">Testament</a></li>
<li><a href="#convention-unanime-actionnaires">Convention unanime des actionnaires</a></li>
</ol>
</li>
<li><strong><a href="#negociations-confidentialite">Négociations et confidentialité : l’importance de la bonne stratégie!</a></strong></li>
<li><strong><a href="#valeur-juridique-documents">Entente de principe, offre d’achat et lettre d’intention : la valeur juridique de ces documents</a></strong></li>
<li><strong><a href="#resolution-conflits-negociations">Résolution de conflit lors des négociations : comment sortir d’impasse?</a></strong></li>
<li><strong><a href="#erreurs-a-eviter">Les erreurs à éviter lors du processus d’achat d’entreprise</a></strong></li>
<li><strong><a href="#vente-et-contrats-travail">Qu’arrive-t-il aux contrats de travail lors de la vente de l’entreprise?</a></strong></li>
</ol>
<p><strong> </strong></p>
<h2>Qui sont les professionnels impliqués dans le processus de vente d’entreprise?</h2>
<p>
La vente d’une entreprise est une démarche multidimensionnelle qui nécessite l’intervention d’experts de plusieurs milieux. Le succès, et surtout la profitabilité de votre démarche de transfert, dépend largement du choix d’entourage que vous faites. Il est donc essentiel de comprendre d’entrée de jeu les compétences et le rôle de chacun de ces professionnels dans le processus de vente d’entreprise, ce que nous vous expliquons en détail.</p>
<p><strong>Avocat :</strong> Maître du monde juridique, l’avocat est la clé de voute vers une transaction sécuritaire. Son rôle primordial est de préserver vos intérêts et ceux de votre entreprise par la mise en place de <strong>clauses de confidentialité</strong> et de <strong>vérifications diligentes</strong>, en plus d’être en charge du plus clair des <strong>négociations </strong>avec l’acquisiteur potentiel.</p>
<p>Le choix d’un avocat en droit des affaires spécialisé en fusions, acquisitions et transferts d’entreprise est inévitable pour profiter de conseils adaptés, notamment sur le type de transfert d’entreprise à prioriser. De plus, comme la <strong>négociation </strong>d’une telle transaction débouche habituellement sur <strong>l’émission d’offres d’achat</strong>, de <strong>lettres d’intention</strong> et de <strong>conventions d’achat</strong>, vous serez assuré d’une démarche légale optimale lorsqu’un avocat se charge de leur préparation. La rédaction ou même la modification d’une convention entre actionnaires prend également tout son sens dans le contexte d’une vente d’entreprise; une autre démarche assumée par votre avocat.</p>
<p><strong>Comptable professionnel agréé :</strong> L’expertise d’un <a href="https://soumissionsentreprises.ca/comptable-pour-entreprises/">Comptable professionnel agréé</a> est indispensable pour conclure un transfert d’entreprise. C’est lui qui se chargera de <strong>déterminer la juste valeur de votre entreprise</strong> ainsi que les démarches fiscales nécessaires pour l’optimiser et la rendre plus attrayante aux yeux d’un acheteur potentiel. Par ailleurs, un CPA est tout aussi utile pour le vendeur que pour l’acheteur afin de présenter des contre-expertises sur la valeur alléguée de l’entreprise.</p>
<p><strong>Notaire :</strong> Ce juriste de l’impartialité et de l’avenue non litigieuse peut représenter un allier de taille. L’expertise contractuelle du notaire et son ambition à solder toute transaction d’un esprit d’entente peuvent contribuer joindre les intérêts des parties et à conclure une vente d’entreprise sans conflit. Toutefois, le principal avantage du notaire réside dans <strong>l’authenticité des actes</strong> qu’il rédige.</p>
<p>Faisant autorité d’officier public, tout contrat passé par un notaire <strong>fait preuve de son contenu</strong> et devient difficilement attaquable en cas de litige. C’est là sa principale utilité en tant que membre de votre équipe de transfert d’entreprise, soit d’authentifier toute l’entente conclue avec l’acquéreur. Une telle démarche présente un caractère préventif minimisant les risques de litiges.</p>
<p><strong>Fiscaliste :</strong> Lorsqu’une manœuvre fiscale est nécessaire pour vendre l’entreprise à un acheteur potentiel (pensons notamment à l’exonération des gains en capital), les conseils d’un fiscaliste seront <strong>nécessaires à des fins de conformité et d’optimisation</strong>. Les lois fiscales étant le jargon intelligible qu’elles sont, seule une expérience concrète dans le domaine vous permettra d’accomplir votre manœuvre fiscale dans le respect de la loi.</p>
<p><strong>Courtier en vente d’entreprise :</strong> Cet acteur intervient dès la première manifestation de votre désir de vendre ou d’acheter une entreprise. Son rôle est simple <strong>: trouver le futur acheteur d’une entreprise</strong>. En travaillant de concert avec les autres acteurs de l’équipe de transfert, le courtier en vente d’entreprise trouvera l’acheteur dont le profil correspond aux besoins et souhaits du vendeur.</p>
<p>Bien que ces expertises soient véritablement complémentaires et indispensables, il est tout de même essentiel de <strong>nommer un « maître d’œuvre » ou un « chef d’orchestre »</strong> chargé de superviser la transaction.</p>
<p><strong>La vente d’une entreprise n’est pas le moment de tourner les coins ronds : engagez les meilleurs experts à votre disposition.</strong></p>
<h2>Choisir son équipe de transfert d’entreprise et mise en place du Business Staging</h2>
<p>
Maintenant que les rôles des principaux intervenants du transfert d’entreprise sont clairement établis, la première étape est de <strong>constituer une équipe de gestion</strong>, composée d’un expert de chaque domaine, afin de prendre en charge la transaction. <strong>Faites attention!</strong> La mise en place d’une telle équipe ne signifie pas que vous prendrez place sur les lignes de côté en tant que spectateur; vous demeurez l’épicentre de la transaction et votre rôle de leader au sein de l’entreprise garde tout son sens.</p>
<p><StaticImage alt="Le fonctionnement du business staging vente entreprise" src="../images/business-staging-valorisation-entreprise-avocat-cpa-notaire.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Toute bonne équipe de transfert d’entreprise devrait d’abord compter sur les services d’un <strong>avocat spécialisé</strong> dans le domaine ainsi que sur un <strong>comptable professionnel agréé (CPA). </strong>En étroite collaboration, ces deux experts procèderont à l’étape initiale de la vente, soit le <strong>business staging</strong>. Également appelée la « valorisation d’entreprise », il s’agit d’une démarche visant à rendre une entreprise aussi attrayante que possible aux yeux des acquéreurs du marché.</p>
<p>C’est la chance pour le vendeur d’apporter des améliorations au fonctionnement et à la profitabilité de son entreprise avant de la mettre en vente par l’identification de ses forces, mais surtout de ses faiblesses. Les premières phases du business staging se passent ainsi :</p>
<ul>
<li><strong>Bilan financier de l’entreprise</strong></li>
<li><strong>Analyse du fonctionnement (Ressources humaines, gestion, etc.)</strong></li>
<li><strong>Identification des risques financiers et légaux</strong></li>
<li><strong>Révision des opérations logistiques et de la productivité</strong></li>
<li><strong>Vérification de l’approche marketing et publicitaire</strong></li>
</ul>
<p>À l’évidence, le <strong>business staging</strong> nécessite l’expertise d’une équipe multidisciplinaire étant donné la variété des domaines abordés. L’objectif est de cerner les faiblesses de l’entreprise afin de les corriger et d’augmenter le chiffre d’affaires avant qu’un acheteur ne s’y intéresse de trop près. Plus la démarche creuse en profondeur dans le fonctionnement de l’entreprise, plus elle sera efficace à cerner ses faiblesses.</p>
<p>Une fois les brèches constatées, c’est à ce moment que les <strong>avocats</strong>, <strong>CPA</strong>, <strong>fiscalistes</strong> et <strong>notaires</strong> opèrent leur magie en <strong>élaborant un plan de redressement</strong>. Il n’est aucunement nécessaire que votre entreprise soit en situation de difficulté financière pour qu’elle nécessite cette intervention de valorisation. Après tout, aucun propriétaire d’entreprise ne s’est jamais plaint d’avoir vendu son entreprise trop chère.</p>
<p><strong>Quelle forme prend la démarche de valorisation d’entreprise? </strong>D’un projet à long terme confié à une équipe multidisciplinaire! Un plan comportant divers échéanciers sera mis en place en ciblant des objectifs à atteindre afin qu’au jour venu de vendre ou de transférer votre entreprise, vous puissiez disposer d’une entité brillant de tous ses feux.</p>
<p><strong>Le business staging se prépare à l’avance! </strong>Se réveiller du pied gauche et décider de vendre son entreprise au premier acheteur venu vous privera d’un profit considérable. Si le transfert de votre entreprise se dessine dans votre destin à court ou moyen terme, le moment de mettre en place une équipe chargée de votre business staging est <strong>maintenant</strong>. Il faut de <strong>2 à 3 ans</strong> pour permettre à la valorisation d’entreprise de produire ses effets, sans quoi vous serez pris de court dans votre démarche.</p>
<p>Une fois les faiblesses de votre entreprise identifiées, il est temps de passer à l’action avec un remède concret. Bien qu’un remaniement d’effectifs et une simple optimisation fiscale puissent convenir, <strong>les techniques de</strong> <strong>business staging</strong> ci-dessous sont quelque peu plus robustes :</p>
<p><strong>Dissolution de la concentration : </strong>Un acquéreur potentiel constatant que l’entreprise est l’affaire d’un seul homme omnipotent sera hésitant à se lancer dans son acquisition, puisque la pérennité de l’entité dépend de la présence du propriétaire actuel.</p>
<p>En planifiant une vente, le propriétaire actuel a donc intérêt à <strong>répartir ses pouvoirs de contrôle au personnel de son entreprise</strong>, ou encore à s’engager de demeurer parmi les effectifs pendant un délai de grâce après la vente afin de garantir une douce transition. Cette dissolution peut également avoir lieu à l’égard de la clientèle.</p>
<p>Il est plus intéressant pour un acheteur de devenir propriétaire d’une compagnie comptant sur plusieurs clients qu’un seul, et ce, peu importe la profitabilité de la clientèle unique. La raison est simple : si le client part en raison de la vente, il ne restera plus grand-chose!</p>
<p><strong>Acquisition stratégique :</strong> Cette approche consiste à cibler vos efforts de vente vers les acheteurs qui ont le plus grand intérêt à acheter votre entreprise, soit vos compétiteurs. Cela est surtout profitable lorsque le sort de votre entreprise après votre départ vous importe peu. En l’offrant à vos compétiteurs du marché, ceux-ci entreront en compétition non seulement pour faire augmenter leur chiffre d’affaires, mais surtout pour éviter de voir naître un nouveau compétiteur digne de goliath. Que les enchères commencent!</p>
<p><strong>Exonération du gain en capital :</strong> Les premiers <strong>883 9384$ résultant de la vente d’actions </strong>d’une entreprise sont exempts d’impôt au Québec. Pour qu’une entreprise bénéficie d’un tel avantage fiscal, encore faut-il qu’elle soit constituée en Société par actions. Ainsi, le <ConversionLink to="/avocat-incorporation-entreprise-quebec/" text="business staging peut consister à incorporer l’entreprise" button={false}></ConversionLink>quelques années avant sa vente afin d’optimiser le profit résultant de sa vente.<strong>
</strong></p>
<h2>Méthodes d’évaluation d’entreprise : combien vaut réellement votre compagnie?</h2>
<p>
L’évaluation d’une entreprise n’est pas une démarche uniforme; elle revêt d’un certain dynamisme et peut être fait selon différents critères en fonction du champ d’activité de l’entreprise et de sa structure.</p>
<p><StaticImage alt="Comment procéder à l’évaluation d’une entreprise" src="../images/methodes-evaluation-entreprise-actualisation-flux-baiia-valeur.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Quels sont les objectifs de l’évaluation d’entreprise? </strong>Établir le prix de vente et cerner les acheteurs potentiels! Les principales approches d’évaluation d’une entreprise se fondent soit sur les <strong>actifs</strong>, le <strong>rendement</strong> ou la <strong>juste valeur marchande</strong>.</p>
<p><strong>Juste valeur marchande (JVM):</strong> L’institut canadien des experts en évaluation d’entreprise définit la juste valeur marchande d’une entreprise comme suit :</p>
<p>« Le prix le plus élevé, exprimé en numéraire ou l’équivalent, que l’on peut obtenir sur un marché ouvert qui n’est soumis à aucune restriction, entre deux parties prudentes, bien informées, n’ayant aucun lien de dépendance et agissant en toute liberté »</p>
<p>Le calcul utilisé pour établir la JVM se base sur trois grands critères, soit le rendement de l’entreprise, la valeur de l’argent au moment de l’évaluation ainsi que les risques de non-réalisation reliés aux deux aspects précédents.</p>
<p><strong>Avantages et inconvénients :</strong> Cette méthode d’évaluation a l’avantage d’effectuer un comparatif fiable du marché, mais possède tout de même un élément subjectif relié à la perception du risque.</p>
<p><strong>Actif net redressé : </strong>Cette technique d’évaluation consiste à prendre le calcul de la juste valeur marchande et d’y soustraire les passifs. Elle s’avère particulièrement pertinente aux entreprises détenant des biens immobiliers.</p>
<p>Avantages et inconvénients : L’utilisation de cette technique aura l’avantage de donner la valeur d’exploitation réelle de l’entreprise et sera utilisée lorsqu’une faible valeur des actifs d’une entreprise est reliée à des biens intangibles.</p>

<p>MÉTHODE DE CALCUL :</p>
<p>Avoirs des actionnaires + Ajustement entre la valeur marchande et la valeur comptable des actifs et dettes = <strong>valeur des actifs net redressés</strong></p>

<p><strong>Valeur de liquidation forcée</strong><strong> :</strong> La valeur obtenue à l’issue d’une telle évaluation est celle d’une entreprise en péril dont le vendeur est contraint de vendre rapidement sur le marché, impliquant des efforts de vente limités. À l’issue du calcul, on obtient le montant qui restera entre les mains du propriétaire ou des actionnaires.</p>
<p>Avantages et inconvénients : Ce calcul est pertinent si votre entreprise présente des difficultés financières ou si vous souhaitez vous préparer au pire, mais elle ne vous donnera pas la valeur que vous obtiendrez sur un marché compétitif pour une entreprise en santé.</p>
<p><strong>Valeur de liquidation ordonnée :</strong> Cette valeur diffère de celle susmentionnée dans la mesure qu’elle considère une liquidation lors de laquelle le vendeur dispose de temps pour la vendre, malgré le fait que la survie de l’entreprise soit compromise. Dans les deux cas, le calcul procède ainsi :</p>

<p>MÉTHODE DE CALCUL :</p>
<p>Avoirs des actionnaires + Ajustement entre la valeur marchande et la valeur comptable des actifs et dettes – coûts et impôts de la vente – coûts de distribution = <strong>valeur de liquidation</strong></p>

<p><strong>
</strong><strong>Valeur du multiple des bénéfices (BAIIA) :</strong> Résumée simplement, la méthode du BAIIA consiste à déterminer la valeur de l’entreprise en assumant qu’elle soit achetée sans aucune dette, tout en tenant compte de l’argent apporté par les fournisseurs. Le BAIIA représente le flux monétaire généré par l’exploitation courante de l’entreprise. Un calcul d’ajustement doit être effectué afin d’éliminer les données aberrantes de la valeur.  Au final, la valeur de l’entreprise sera déterminée par un multiple du BAIIA servant à mesurer la capacité d’une entreprise à tirer un bénéfice de son exploitation.</p>
<p><strong>Actualisation des flux monétaires prévisibles:</strong> Cette approche vise à déterminer le prix d’évaluation en tenant compte des flux monétaires <strong>futurs</strong> les plus probables et à les actualiser à la date de la mise en vente. Il s’agit d’un calcul complexe nécessitant une expertise comptable aguerrie.</p>
<p>Avantages et inconvénients : Procéder à l’actualisation des flux monétaires prévisibles est indispensable pour les entreprises en croissances afin d’optimiser leur valeur.</p>

<p>DONNÉES INCLUSES DANS LE CALCUL :</p>
<p>Projection du BAII sur 5 ans </p>
<p>Calcul de variation du fonds de roulement et de l’investissement soutenable</p>
<p>Calcul des flux monétaires actualisés</p>
<p>Calcul valeur terminale selon l’actualisation</p>
<p>Calcul du total et ajustements</p>
<p>Calcul valeur des actions et dettes</p>
<p><strong>Résultat du calcul = Valeur des actions (équité), taux de croissance soutenable et d’actualisation</strong></p>

<p><strong>Approches fondées sur le marché :</strong> Ce mode d’évaluation consiste à procéder à une comparaison entre l’entreprise sous-évaluation et celles identiques à elles, en tenant compte du rendement passé de celle-ci. Il faut procéder à un choix des paramètres à comparer, ce qui peut varier d’une industrie à une autre.</p>
<p>Avantages et inconvénients : Présentant l’avantage de la simplicité, les approches fondées sur le marché sont cependant moins précises que leurs alternatives pour la seule raison qu’aucune entreprise comparative ne sera parfaite, ce qui ouvre la porte à une marge d’appréciation.</p>
<p>Au cours d’une analyse objective, certains facteurs et données sur l’entreprise seront pris en compte afin d’établir la juste valeur de celle-ci. Vous pouvez donc commencer à récolter les informations suivantes pour vous donner une idée :</p>
<p><strong>Bénéfices avant intérêts et impôts (BAII)
</strong>
<strong>Marge brute moyenne</strong></p>
<p><strong>Revenus annuels</strong></p>
<p><strong>Industrie et nombre d’années d’exploitation</strong></p>
<p>Pour que l’évaluation choisie reflète la valeur réelle de votre entreprise, l’intervention d’un <strong>Comptable professionnel agréé</strong> et l’assistance de <strong>l’avocat spécialisé en transfert d’entreprise</strong> sont essentielles. Il s’agit de votre porte d’entrée vers le marché de la vente d’entreprise.</p>
<h2>L’importance du bon acheteur pour assurer la pérennité de votre entreprise</h2>
<p>
Le choix du bon acheteur se fait avant tout de façon stratégique dans l’optique de maximiser les profits lors de la vente, ce que le <strong>business staging</strong> devrait favoriser. Cependant, des critères additionnels doivent être considérés si la survie et la prospérité de votre entreprise après votre départ vous tiennent à cœur. Après tout, vous n’avez pas passé votre vie à bâtir une entreprise pour la voir partir en fumée lors de la passation des pouvoirs.</p>
<p><StaticImage alt="La démarche pour trouver le bon acheteur pour votre entreprise" src="../images/choix-acheteur-transfert-entreprise-survie-avocat-cpa-notaire.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<ul>
<li><strong>Réputation et connaissances :</strong> Faites vos recherches sur votre acheteur potentiel vous donne de sérieux indices de son sérieux, de son expérience et de ses techniques de négociation. Ses expériences transactionnelles passées et ses connaissances sur votre domaine d’activité vous donneront également un avant-goût de la tenue des négociations et de l’avenir de l’entreprise après la vente.</li>
<li><strong>Capacité/ Solvabilité :</strong> Cet aspect réfère aux moyens financiers et humains de l’acheteur à investir dans l’entreprise afin de poursuivre sa croissance, et ce, dès le transfert de propriété. Ce critère comporte évidemment un élément subjectif, soit celui de l’intention de l’acheteur de continuer à exploiter l’entreprise.</li>
<li><strong>Intention de protéger l’emploi des employés :</strong> La relation que vous avez développée avec votre personnel ne s’efface pas par la vente de votre entreprise. Bien que l’acheteur ait des obligations légales à l’égard de vos employés, il vaut mieux opter pour l’acheteur qui souhaite réellement les garder que celui qui s’en voit contraint.</li>
</ul>
<p><strong>Qu’en est-il du rachat par les membres de la direction?</strong> Bien qu’étant applicable seulement dans des situations uniques, le rachat par les gestionnaires non propriétaires de l’entreprise est l’une des méthodes les plus sûres d’assurer la survie de celle-ci. Comme la culture de gestion ne change pas et que seuls les titres de propriété sont modifiés, le cours normal des opérations peut se poursuivre sans interruption.</p>
<p><strong> </strong></p>
<h2>Vente d’actifs, d’actions ou fusion? Des incidences légales et fiscales!</h2>
<p>
L’une des premières discussions à avoir avec votre équipe de transfert d’entreprise tournera autour du choix du type de vente d’entreprise : <strong>vendrons-nous les actions, les actifs, un mélange des deux ou procèderons-nous à une fusion?</strong> Telles sont les questions auxquelles votre équipe fournira la réponse. Ce choix a une incidence fondamentale sur le fonctionnement et surtout le contenu de la vente.</p>
<p><strong>Vente d’actifs : </strong>De loin la méthode de transfert la plus simple, la vente d’actifs consiste à liquider—vous l’aurez deviné—les biens appartenant à la compagnie.  Si le fonctionnement est simple, le jeu se corse au niveau du choix d’actifs à acquérir.</p>
<p><strong>Avantages</strong> : Le principal avantage est le fait que l’acheteur n’est pas tenu responsable des actes de l’entreprise, au même titre qu’il n’est pas tenu aux dettes de celle-ci. Cela devient avantageux lorsque l’entreprise est en situation déficitaire ou fût victime d’importantes pertes.</p>
<p><strong>Inconvénients</strong> : Les lois fiscales jouent contre l’option de la vente d’actifs, car elles ne permettent pas de profiter de l’exonération du gain en capital au même titre que la vente d’actions. Notamment, à l’achat d’un bien immobilier, le paiement des droits de mutation sera applicable.</p>
<p><strong>Exemple d’actifs</strong>: L’achalandage, la marque de commerce, l’équipement, l’inventaire, etc.</p>
<p><strong>Vente d’actions : </strong>Ce type de vente consiste à transférer les parts du propriétaire dans le capital-actions de la compagnie au nouvel acheteur. Avec ce transfert vient le contrôle des activités de l’entreprise.</p>
<p><strong>Avantages</strong> : Au niveau du vendeur, l’exonération du gain en capital est le plus grand avantage, du moment qu’elle satisfait les critères d’admissibilité. Du côté de l’acheteur, c’est plutôt les pouvoirs et le niveau de contrôle sur l’entreprise qui représenteront les plus grands avantages.</p>
<p><strong>Inconvénients</strong> : Les seuls inconvénients résultant de la vente d’actions sont ceux du risque encouru par l’acheteur. Celui-ci devient tenu, de par son achat des actions, aux dettes inconnues de la compagnie. L’étape de la vérification diligente est d’autant plus importante lors d’une vente d’actions.</p>
<p><strong>Fusion : </strong>N’étant pas un transfert d’entreprise à proprement parler, la fusion d’entreprise consiste en l’union de deux personnes morales, incluant les actifs et les passifs de ces deux entités n’en constituant maintenant qu’une seule.</p>
<p><strong>Avantages :</strong> Le potentiel de croissance multiplié, la diversification des opérations, l’élimination de la compétition et la consolidation du chiffre d’affaires ne sont que quelques-uns des avantages de fusionner deux compagnies sous une seule entité.</p>
<p><strong>Inconvénients :</strong> Les coupures, pertes d’emplois et les pertes de productivité sont les principaux inconvénients de la fusion. Bien que la plupart se ressentent seulement à court terme, ils peuvent affecter la rentabilité de l’entité nouvellement créée. 
<strong>
À quoi ressemble la vente hybride? </strong>Dans la réalité du transfert d’entreprise, il est tout à fait possible de réaliser une vente mixte entre la vente d’actions et la vente d’actifs. Il s’agira de faire le choix des actifs et des actions qui feront partie de la vente en en faisant une mise en balance des avantages fiscaux encourus.</p>
<h2>En quoi consiste la vente du fonds de commerce?</h2>
<p>
Vendre un fonds de commerce est principalement utile pour les entreprises dont l’exploitation nécessite une machinerie, de l’équipement ou un inventaire spécifique. Au niveau légal, le fonds de commerce se définit comme le patrimoine ainsi que « <strong>l’ensemble des biens affectés à l’exercice des activités de l’entreprise</strong> ». Bien plus que les simples objets de l’entreprise, le fonds de commerce comprend également des éléments incorporels qui sont inclus lors d’une vente.</p>
<p>On peut notamment penser à la <strong>clientèle</strong> et <strong>l’achalandage</strong> qui correspondent à la relation d’affaires entre le public et l’entreprise. L’achat d’une clientèle est évidemment dépourvu de toute garantie quant à la fidélité de celle-ci, car elle n’est aucunement liée à l’entreprise.</p>
<p><StaticImage alt="Vente du fonds de commerce avantages inconvénients" src="../images/vente-fonds-commerce-fonctionnement-principe-avantages-avocat-cpa.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il peut donc être intéressant d’acheter fonds de commerce lorsqu’il s’agit d’une entreprise opérant avec des marchandises particulières, des biens spécialisés ainsi qu’une clientèle fiable. Un exemple est celui de la vente d’un salon de barbier qui vendrait ses chaises, son équipement et la propriété incorporelle qu’est la clientèle.</p>
<p><strong>La vente d’un fonds de commerce permet mitiger les pertes d’un commerce en souffrance au profit d’un investisseur intéressé à le récupérer. </strong></p>
<h2>Transfert d’une entreprise familiale : un mécanisme particulier</h2>
<p>
Le transfert d’une entreprise à caractère familial implique de garder celle-ci dans le giron familial. Les lois fiscales permettent d’effectuer ce transfert d’une manière particulière dans un tel contexte afin de réduire le montant d’imposition et de préserver l’unité de l’entreprise.</p>
<p><strong>Gel successoral</strong><strong> :</strong> Cette méthode est grandement utilisée par les propriétaires d’entreprises familiales. Elle consiste à <strong>transférer la future plus-value des actions de l’entreprise entre les mains de la relève familiale afin qu’elle croisse entre leurs mains.</strong> Cela permet au propriétaire de conserver la valeur réelle et de reporter l’imposition sur le gain en capital au moment de la véritable cession.</p>
<p>Les économies fiscales sont substantielles lors d’un tel transfert, mais la démarche nécessite la modification du testament, la mise en place d’une convention d’actionnaires ainsi que les conseils d’un juriste fiscaliste. C’est toutefois une méthode éprouvée de transférer une entreprise à caractère familial.</p>
<p><strong>Fiducie discrétionnaire :</strong> Une fiducie familiale discrétionnaire est l’outil par excellence pour compléter le gel successoral et assurer la relève de l’entreprise. Bénéficiant d’un patrimoine autonome, il est possible pour elle de souscrire à des actions ordinaires de votre compagnie suite à un gel afin que leur plus-value s’y accumule. Cela aura pour effet de minimiser l’impôt à payer au décès.</p>
<p>Le plus grand avantage de détenir des actions par l’entremise d’une fiducie familiale est que <strong>chaque bénéficiaire profite de l’exonération sur le gain en capital</strong> lors de la vente d’actions. C’est une façon de préparer la relève avec un impact fiscal minimal.</p>
<p><strong>Testament</strong><strong> :</strong> Le testament connaît plusieurs utilités lors du transfert d’une entreprise familiale, notamment celle de stipuler la répartition des actions du gel successoral, de créer une fiducie testamentaire, ainsi que la répartition d’autres actifs de l’entreprise.</p>
<p><strong>Convention unanime des actionnaires</strong><strong> : </strong>En suivant les mécanismes ci-haut, le jour arrivera où les actions seront entre les mains de leurs détenteurs prévus. Cette situation est propice à conflit et c’est pourquoi les auteurs du gel successoral et de la fiducie testamentaire se doivent de prévoir<ConversionLink to="/avocat-redaction-convention-actionnaires/" text="une convention entre actionnaires" button={false}></ConversionLink> qui liera également les futurs détenteurs d’actions.</p>
<p>Les clauses stipulant que les décisions importantes doivent être prises unanimement ainsi que les modalités relatives au rachat et aux droits de préemption doivent absolument être incluses.<strong>
</strong></p>
<h2>Négociations et confidentialité : l’importance de la bonne stratégie!</h2>
<p>
Vu la nature délicate de la négociation d’une vente d’entreprise, la transaction doit être protégée par des <strong>dispositions de confidentialité</strong> et même <strong>d’exclusivité</strong>. Cela s’avère surtout pertinent lorsque les parties ont intérêt à ce que la transaction demeure secrète aux oreilles de la concurrence.</p>
<ul>
<li><strong>Clauses de confidentialité :</strong> Celles-ci doivent être limitées au temps nécessaire pour accomplir les négociations sans contraintes. Il est fréquent de fixer la date à la fin des négociations, qu’elles débouchent ou non sur un accord, ou encore sur un certain nombre d’années suivant l’émission de la lettre d’intention. Ce faisant, l’acheteur a accès à l’information nécessaire à sa prise de décision, sans que le vendeur n’encoure de risque quant à leur divulgation.</li>
<li><strong>Exclusivité des négociations :</strong> Donnée au bénéfice de l’acheteur, l’exclusivité des négociations empêche le vendeur de se tourner vers d’autres acheteurs pendant le délai fixé par les parties. Cela donne plus de poids à l’acheteur lors des pourparlers, alors il s’agit d’une décision stratégique à prendre avec votre équipe de transfert. Une chose est certaine, établissez une limite temps claire et courte afin de préserver votre pouvoir de négociation.</li>
</ul>
<p><strong>Quels renseignements divulguer à l’acheteur potentiel?</strong> La méthode compte-goutte est celle que vous devriez prioriser afin de préserver l’information confidentielle de votre entreprise. Plus l’acheteur démontrera son sérieux, au cours des négociations, plus vous divulguerez d’informations sensibles, tout en ne révélant que ce qui s’avère essentiel pour l’acheteur.</p>
<h2>Les étapes d’une vérification diligente adéquate</h2>
<p>L’étape de la vérification diligente se déroule lorsque la lettre d’intention a été dument signée par l’acheteur. Les experts de l’équipe de transfert devront passer au peigne fin certains documents présentés par le vendeur afin d’établir leur conformité aux déclarations initiales ainsi que pour cerner les risques de la transaction.</p>
<p><StaticImage alt="Vérifications diligentes vente entreprise avocat" src="../images/etapes-verifications-diligentes-comptable-etats-financiers-cpa-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<ul>
<li><strong>Analyse de l’actif et des états financiers : </strong>Vérifier que la santé financière de l’entreprise est à la hauteur de ce que le vendeur a indiqué est essentiel avant de pousser la transaction plus loin. Toutes les <strong>dettes</strong>, <strong>hypothèques</strong> et <strong>contrats passés</strong> par l’entreprise doivent être portés à la connaissance de l’acheteur potentiel pour lui permettre de procéder à son analyse.</li>
<li><strong>Visite des lieux d’exploitation :</strong> Il essentiel pour l’acheteur de pouvoir procéder à la vérification des opérations sur les lieux mêmes d’exploitation. Cela doit se faire de façon discrète afin de ne pas sonner l’alarme auprès des employés, mais également en assurant une certaine confidentialité du côté de l’acheteur.</li>
<li><strong>Rencontre avec les employés clés :</strong> Avoir l’heure juste sur le fonctionnement d’une compagnie ne se fait pas sans passer par l’expérience humaine des employés. S’enquérir auprès des dirigeants du mode de fonctionnement de l’entreprise, de la gestion des effectifs et autres données permet une analyse complète.</li>
<li><strong>Remise des documents essentiels :</strong> On pense notamment aux principaux contrats d’affaires, aux permis nécessaires à l’exercice des activités, les procès-verbaux ainsi que la convention d’actionnaires dans le cas d’une société par actions. Une liste de la clientèle sera aussi divulguée à l’acheteur.</li>
</ul>
<p>Afin de protéger le vendeur, tout en permettant à l’acheteur d’avoir accès aux informations essentielles, il est important qu’un avocat rédige des accords de confidentialité empêchant la divulgation des éléments récoltés lors des vérifications diligentes. Cela est surtout important si la transaction avorte.</p>
<h2>Entente de principe, offre d’achat et lettre d’intention : la valeur juridique de ces documents</h2>
<p>
Plusieurs documents sont publiés au cours de l’évolution de la transaction et il importe pour chacune des parties de connaître leur valeur juridique. Il est fréquent pour un acheteur potentiel d’envoyer des documents manifestant son intérêt l’entreprise sans souhaiter s’engager légalement. Il faut donc distinguer ce qui a une valeur juridique contraignante des simples pourparlers.</p>
<p><strong>Lettre d’intention : </strong>L’émission d’un tel document est la façon pour l’acheteur de manifester son intérêt sérieux à acheter l’entreprise, sans toutefois s’engager formellement. La lettre d’intention lui sert plutôt à établir les règles des négociations, les ententes de confidentialité et autres modalités concernant la transaction.</p>
<p>Il est important de rédiger la lettre d’intention de façon claire pour ne pas engager l’acheteur formellement, au risque de la confondre à l’offre d’achat, qui elle a une valeur légale.</p>
<p><strong>Offre d’achat :</strong> À cette étape l’acheteur démontre son intention réelle et concrète d’acheter l’entreprise. Cette expression de volonté se fait par l’émission d’une offre d’achat édictant les termes auxquels il est prêt à acheter l’entreprise. À l’inverse de la lettre d’intention, l’offre d’achat fait figure d’entente contraignante entre les parties et l’acheteur sera lié au vendeur si ce dernier l’accepte.</p>
<p><strong>Contrat de vente :</strong> La démarche entière de transfert d’entreprise se solde par la conclusion d’un contrat de vente qui contiendra les modalités de paiement, les garanties offertes ainsi que le prix que l’acheteur s’engage à verser.</p>
<h2>Les clauses essentielles du contrat de vente d’entreprise</h2>
<p>Le contrat de vente d’entreprise fait figure d’étape finale. Une rédaction claire, détaillée est sans équivoque s’impose pour prévenir tout litige à naître, mais surtout pour préserver l’investissement de l’acheteur. Pour atteindre ces objectifs, l’insertion des clauses suivantes est inévitable.</p>
<p><StaticImage alt="Conseils contenu contrat vente entreprise" src="../images/clauses-essentielles-contrat-entreprise-prix-confidentiel-concurrence.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Description de l’acheteur, du vendeur et de l’entreprise :</strong> L’identification adéquate des parties s’impose évidemment, mais c’est surtout la description de l’entreprise et du type de vente qui doit être détaillé laborieusement. Acheter un fonds de commerce d’une entreprise et les actions d’une entreprise diffère largement et les termes du contrat devraient refléter pareille distinction via une rédaction précise.</p>
<p><strong>Prix, mode de paiement et financement :</strong> L’acheteur et le vendeur ont la liberté de s’entendre sur les modalités de paiement. C’est surtout la présence d’un <strong>financement</strong> ou <strong>d’une balance de prix de vente</strong> qui doit être indiqué clairement au contrat. La date du premier paiement et des versements subséquents devrait également apparaître à l’écrit, au même titre que ses modalités.</p>
<p><strong>Représentations et garanties du vendeur :</strong> Sous cette clause, le vendeur déclare tous les faits connus de l’entreprise qu’il s’apprête à transférer à l’acheteur afin de certifier l’état de l’entreprise. Par exemple, le vendeur peut faire des représentations sur l’absence de conflits de travail, la qualité des états financiers déclarés et sur tout autre élément pertinent. Ces représentations sont accompagnées de garanties qui visent à certifier que les représentations du vendeur sont exactes, sous peine de quoi il sera tenu d’indemniser l’acheteur.</p>
<p><strong>Clause de non-concurrence :</strong> Cette protection est instaurée au bénéfice de l’acheteur. Le vendeur, ayant passé des années à bâtir l’entreprise, est certainement passé maître du domaine et a probablement acquis des partenaires d’affaires clés. Le voir vous vendre son entreprise pour repartir à zéro avec un tel bagage minerait votre investissement. Prévoyez une clause de non-concurrence et de non-sollicitation de clientèle à même le contrat de vente prévoyant une limite de temps et de territoire raisonnable.</p>
<p><strong>Les engagements d’indemnisation :</strong> Cette clause est principalement applicable dans un contexte transitoire et sert à pallier aux réclamations des tiers qui n’avaient pas été prévus au contrat, telles qu’un compte de taxe ou une cotisation d’impôt due. La clause prévoira le mécanisme par lequel le vendeur compensera l’acheteur et les options qui lui sont offertes pour le faire. Celle-ci peut également servir à compenser l’acheteur lorsqu’une déclaration erronée lui a été présentée.</p>
<h2>Résolution de conflit lors des négociations : comment sortir d’impasse?</h2>
<p>
Le temps est une ressource clé dans une démarche de transfert d’entreprise la gaspiller dans une impasse diminue vos chances de conclure la transaction avec succès. Lorsqu’un conflit survient, confiez la démarche de résolution à une<ConversionLink to="/services-avocats-mediation-arbitrage-quebec/" text="instance privée de règlement de différends tel que l’arbitrage ou la médiation." button={false}></ConversionLink></p>
<p>En optant pour la <strong>médiation</strong>, un tiers neutre se placera entre l’acheteur et le vendeur pour tenter de trouver un terrain d’entente et prévenir la naissance d’un litige. Cette démarche se fait nécessairement sur une base consensuelle de part et d’autre, cependant, mais possède l’avantage d’être rapide et peu dispendieuse.</p>
<p>En revanche, <strong>l’arbitrage</strong> se présente comme l’alternative aux tribunaux et consiste à remettre entre les mains d’une personne qualifiée autre qu’un juge le pouvoir de trancher votre différend. Il s’agit d’une option plus rapide que les tribunaux et a l’avantage d’être <strong>strictement confidentielle</strong>, contrairement aux cours de justice publique.</p>
<h2>Les erreurs à éviter lors du processus d’achat d’entreprise</h2>
<p>
<strong>Vous y êtes presque!</strong> Vous êtes maintenant prêt à vendre et à encaisser le fruit de vos efforts. Tout ce qu’il vous reste à faire, c’est de ne pas vous mettre les pieds dans les plats en finalisant la démarche de vente d’entreprise. À ce titre, trop de propriétaires commettent les mêmes erreurs lorsque leur désir de vendre se concrétise. Voici les plus fréquentes et les façons de les éviter!</p>
<p><strong>Surestimation de la valeur réelle :</strong> Le biais du propriétaire d’entreprise à l’égard de la valeur de son entreprise est puissant. Le danger est d’afficher un prix que vous croyez juste pour finalement le baisser lorsque vous constatez l’absence d’intérêt sur le marché. Cela envoie un message négatif à vos acheteurs potentiels quant à la rentabilité de votre entreprise. Pour éviter que vos sentiments ne brouillent votre jugement, faites évaluer votre entreprise par un tiers indépendant.</p>
<p><strong>Négliger le potentiel de croissance :</strong> Choisir le prix de vente d’une entreprise en croissance présente le délicat problème d’établir son prix en fonction de sa valeur future. En ne considérant que sa valeur actuelle, vous vous priverez de la valeur que votre entreprise prendre entre le moment de sa mise en vente et le transfert de propriété. Sans parler du fait que la prise de valeur future doit elle aussi être prise en compte. Une équipe de transfert compétente ne manquera pas d’inclure un tel profit potentiel au prix de vente.</p>
<p><strong>Tenue de livres déficiente :</strong> Les registres et livres de votre entreprise sont les meilleurs indices que possède l’acheteur quant à la solidité de votre entreprise. Une tenue bâclée est donc un drapeau rouge pour un acheteur sérieux qui pourrait refroidir ses ardeurs. Si une telle négligence était présente dans votre entreprise, le temps d’y remédier est maintenant.</p>
<p><strong>Attendre trop longtemps avant de vendre :</strong> Attendre d’avoir atteint les limites de sa patience avant de mettre son entreprise en vente est la recette vers une vente précipitée et des profits laissés de côté. Préparez cette vente plusieurs années à l’avance, et ce, même si vous avez toujours de l’intérêt à exploiter activement votre entreprise.</p>
<h2>Qu’arrive-t-il aux contrats de travail lors de la vente de l’entreprise?</h2>
<p>
Vous vous souciez du sort de vos employés après la vente de votre entreprise? Sachez que la règle générale veut que <strong>la vente ou autre aliénation d’une entreprise n’a pas pour effet de mettre un terme aux contrats de travail des employés</strong> et la loi prévoit même que l’acheteur est lié par ceux-ci.</p>
<p><StaticImage alt="Les erreurs qui surviennent lors du transfert d’entreprise" src="../images/contrat-travail-congediement-vente-entreprise-droits-employes.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il existe cependant des façons pour un acheteur de mettre un terme légalement à ces liens d’emploi. Celui-ci devra justifier la terminaison des contrats par des motifs valables aux yeux de la loi, tel qu’une restructuration d’entreprise ou la préservation des intérêts économiques de l’entreprise. En présence d’un <ConversionLink to="/avocat-droit-travail-congediement-quebec/" text="congédiement illégal" button={false}></ConversionLink>, les employés visés auront des recours contre l’employeur.</p>
<p>De plus, il est prévu que l’acheteur se doit de <strong>maintenir les conditions de travail </strong>des employés, ce qui inclut leur salaire, avantages, ancienneté ainsi que les relations syndicales en place. Au final, <strong>aucun congédiement ne peut être justifié par le transfert d’entreprise à lui seul.</strong></p>
<h2>Faites le choix d’une équipe spécialisée pour la vente ou le transfert de votre entreprise!</h2>
<p><strong>Vous commencez à penser à vendre votre entreprise?</strong> Le moment de contacter les experts en vente, transfert et achat d’entreprise est maintenant! Mettre le processus en branle et trouver l’acheteur qui correspondra à vos besoins requiert du temps et des efforts réalisés par les meilleurs experts légaux et financiers. Il n’en tient qu’à vous de les contacter.</p>
<p><strong>Soumissions Avocat</strong> est la référence de choix dans la province pour trouver les <strong>avocats</strong>, <strong>CPA</strong> et <strong>fiscalistes</strong> spécialisés en transfert d’entreprise. Leurs précieux conseils vous permettront de passer à la prochaine étape en encaissant un profit digne des efforts consacrés à l’entreprise tout au long de votre vie.</p>
<p><strong>Pour obtenir l’heure juste sur la valeur réelle de votre entreprise, remplissez le formulaire au bas de la page pour être mis en contact avec des avocats spécialisés en transfert d’entreprise.</strong></p>
<p><strong>À quoi bon attendre, la démarche est gratuite, simple et ne vous engage en rien, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost29
  